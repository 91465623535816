import React, { Component } from "react";

class About extends Component {
	render() {
		return(
			<div className="about">

				<div className="row">

					<div className="width50">
		        <h1>About</h1>

		        <h3>The Qualia Report is a small independent publisher located in
						the town of Great Lespen. At the present time, we have two part-time
						columnists.
						<br /><br />
						Interested in writing for us? Please send us an email with a sample of
						your writing.</h3>
		      </div>

					<div className="imgContainer">
						<img
							className="imgMed"
							src={process.env.PUBLIC_URL + "../images/bundle.jpg"}
							alt="Photo by Denny Müller on Unsplash" />
					</div>
				</div>
			</div>
		);
	}
}

export default About;
