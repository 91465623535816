import React, { Component } from "react";
import { Link } from 'react-router-dom';

class Sidebar extends Component {
	render() {
		return(

				<div className="sidebar">
        <h1><span className="black">Articles</span></h1>

          <h3>
  					<ul>
  						<li>
  							<Link to="/article1">
									<span className="black">10/30/19:</span> <br />
									The Rituality of Friday Night
								</Link>
								<br /><br />
  						</li>
  						<li>
                <Link to="/article2">
									<span className="black">11/7/19:</span> <br />
									Duality: The Coexistence of Two Worlds</Link>
              </li>
  					</ul>
          </h3>
				</div>

		);
	}
}

export default Sidebar;
