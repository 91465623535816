import React, { Component } from "react";

class Contact extends Component {
	render() {
		return(
			<div className="about">

				<div className="row">

					<div className="width50">
						<h1>Contact</h1>
							<h3>Email: qualiareport@gmail.com </h3>

							<br />

						<h1>Email Newsletter</h1>
						<h3>Please send us an email asking to be added to our mailing list.</h3>


					</div>

					<div className="imgContainer">
						<img
			        className="imgMed"
			        src={process.env.PUBLIC_URL + "../images/letter.jpg"}
			        alt="Photo by Debby Hudson on Unsplash" />
					</div>
      	</div>
		</div>
		);
	}
}

export default Contact;
