import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';

class Home extends Component {
	render() {
		return(

			<div className="row">

				<Sidebar />

				<div className="homeBody">

					<div className="row">

						<div className="col">

      	<h1>Quirky independent reviews</h1>

        	<h2>Established in 2018</h2>

	        <h3>
	          We primarily focus on reviewing lesser-known new media fiction.
						Have a suggestion as to what we should read &amp; review next? We
						love recommendations.
	        </h3>
      	</div>

				<div className="imgContainer">

				<img
					className="imgMed"
					src={process.env.PUBLIC_URL + "../images/bookstack.jpg"}
					alt="Photo by Aliis Sinisalu on Unsplash" />
				</div>

			</div>
			</div>
			</div>
		);
	}
}

export default Home;
