import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';

class Article2 extends Component {
	render() {
		return(

			<div className="row">

				<Sidebar />

				<div className="article">
					<h1>Duality: The Coexistence of Two Worlds</h1>

					<h2>by Mannon Night</h2>

					<h3>
	        As though we (the readers) are becoming self-aware as well, we gain
	        access to her world via the portal of awakening, and at once we too are
	        acutely aware of the inexorability of duality: wakefulness and sleep;
	        life and death; sensation and oblivion. The first word we behold is her
	        name<sup>1</sup>: it is the origin from which all life springs. The symbolism is
	        unambiguous: she is a being intensely invested in the practice of
	        observation, and it is through her eyes that we experience this realm,
	        painted floridly and at times gratuitously with terpsichorean images.
	        Simultaneously there is a hyper-awareness of time (“time of day,”
	        “dawn of time,” “the wrong season,” “moments from realization,”
	        “too early,” “too soon,” “another year,” “another few hours,” “the
	        day”) and an uncanny denial of its existence (the freeze-frame of her
	        inert form rooted to the earth, motionless despite the thrashing of
	        her negligee against her thighs and her hair against her face). She
	        is briefly contemplative before again slipping to the other side<sup>2</sup>.

	        <br /><br />

	        The character of the wind returns in the second scene, but against a
	        new backdrop, it is unrecognizable - the wide, indifferent expanse of
	        the charcoal field has been replaced with the reassuring, polychromatic
	        clutter of a well-worn farmhouse. It no longer uses its fingers to
	        transform her plait into a noose, but rather leans into the backdoor,
	        encouraging its lazy slam-shut and creak-open articulation of greetings
	        and farewells, a morse code of arrivals and departures, suggesting the
	        permeability of this doorway, its familiarity, and the quotidian warmth
	        of this artifice/edifice.

	        <br /><br />

	        We too are breeze-born, riding along with her on the back of her
	        bicycle, our arms wrapped around her waist with the permission of a
	        growing friendship, but the palpable self-awareness of incipient
	        rapport. As she coasts through the countryside, we encounter our first
	        glimpse of what may be an indication of her amorous proclivities.
	        Inspired by the past, but injected with a contemporary compulsion to
	        rebel, she appropriates the romanticism of the ‘sonnet’ embodied
	        instead in the Woolfian tradition of stream-of-consciousness
	        prose poetry. The gravity of the seasons weighs heavily on her, and
	        already we are reminded of the importance of eyes, of the liminal
	        realms they permit or prohibit (the boolean<sup>3</sup> nature of the swinging
	        farmhouse door; open: true; closed: false. Or is it open: false;
	        closed: true?).

	        <br /><br />

	        She speaks of strangers as though she is actively weaving the spell,
	        the ritual of her diction breathing life into the folly, buoyed by the
	        crowd<sup>4</sup> at the marketplace, levitating the bubble of fantasy into the
	        air, engulfing them all. A fissure forms: she sees Her, walking amongst
	        the rows but perhaps lifted by her magic, because she observes with
	        mundane verisimilitude that “her feet didn’t quite touch” <span className="redacted">
					_____________________________ ________________
					____________________ _____________________________________________
					_____________________________________ _____________________________________
					_____________________________________________________________ _____________________________________
__				____________________________________ _________________________________________________ _____________________________
					_______________ __________________________________ _________________________ _________________________ _______________
					_______________ ________________________________________________ _________________________________________ _
					______________________ ___________________________________ _______________________________
					_________ _______________
					_____________________ _______________________ ___________________ ___________
					</span>
	        </h3>

					<br /> <br /> <br /> <br /> <br />

	        <h5>
	        <sup>1</sup> In Greek mythology, Iris is the messenger of the gods, traveling to
	        earth upon a rainbow. It also describes the colored part of the eye.
	        <br />
	        <sup>2</sup> See the article “Sleep as the Final Frontier” by Sybil [redacted]
	        (In press, 2019).
	        <br />
	        <sup>3</sup> In computer science, the Boolean data type has one of two possible
	        values (true or false), and is most often associated with conditional
	        statements, which allow for different actions by changing the control
	        flow
	        <br />
	        <sup>4</sup> Edith Piaf, ‘La Foule’
	        </h5>
				</div>

			</div>
		);
	}
}

export default Article2;
