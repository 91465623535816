import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Sidebar from './Sidebar';

class Article1 extends Component {
	render() {
		return(

			<div className="row">

				<Sidebar />

				<div className="article">
	        <h1>The Rituality of Friday Night</h1>

	        <h2>by Mannon Night</h2>

	        <h3>
	        [article text here]
					</h3>
      	</div>
			</div>
		);
	}
}

export default Article1;
