import React, { Component } from "react";
import NavBar from './NavBar';

const header = (
    <div>
      <img
        className="header"
        src={process.env.PUBLIC_URL + "../images/qualia.png"}
        alt="Photo by Joanna Kosinska on Unsplash" />
    </div>
);

class Header extends Component {
  render() {
    return (
      <div>
        {header}
          <NavBar />
      </div>
    )
  }
};

export default Header;
