import React, { Component } from "react";

class Footer extends Component {
	render() {
		return(
			<footer>
        <h3>The Qualia Report &reg; 2018</h3>
      </footer>
		);
	}
}

export default Footer;
