import React, { Component } from "react";
import { Link } from 'react-router-dom';

class NavBar extends Component {
	render() {
		return(
      <div className="navBox">

        <div className="navText">
            <h1>
							<Link to="/">Home</Link>
              &nbsp;&nbsp;&nbsp;
							<img
				        className="email"
				        src={process.env.PUBLIC_URL + "../icons/email.png"}
				        alt="qualia" />
								&nbsp;&nbsp;&nbsp;

							<Link to="/about">About</Link>
              &nbsp;&nbsp;&nbsp;
							<img
				        className="email"
				        src={process.env.PUBLIC_URL + "../icons/email.png"}
				        alt="qualia" />
								&nbsp;&nbsp;&nbsp;

							<Link to="/contact">Contact</Link>
            </h1>
        </div>
      </div>
		);
	}
}

export default NavBar;
