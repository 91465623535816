import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import './App.css';

import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Article1 from './components/Article1';
import Article2 from './components/Article2';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
          <Switch>
            <Route exact path="/" component={Home}/>
              <Route path="/article1" component={Article1}/>
              <Route path="/article2" component={Article2}/>
            <Route path="/about" component={About}/>
            <Route path="/contact" component={Contact}/>
          </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
